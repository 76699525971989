import * as React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import classnames from 'classnames';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import ratesIndexData from '../../data/rates/index.json';
import { BaseLayout, Markdown } from '../../components';
import TermAprSection from './TermAprSection';
import TermApySection from './TermApySection';
import FeeTable from './FeeTable';

enum Tab {
  Loan,
  Savings,
  Fees,
}

export const RatesIndexPageInternal = ({ loanRates, savingsRates, fees }: typeof ratesIndexData) => {
  const { hash } = useLocation();

  const [ tab, setTab ] = useState<Tab | undefined>();

  useEffect(() => {
    let defaultTab = Tab.Loan;

    if (hash === '#fees') {
      defaultTab = Tab.Fees;
    }

    if (hash === '#savings') {
      defaultTab = Tab.Savings;
    }
    setTab(defaultTab);
  }, [ hash ]);

  return (
    <BaseLayout title="Rates">
      <Container className="py-5">
        <Row>
          <Col md={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: tab === Tab.Loan })}
                  onClick={() => setTab(Tab.Loan)}
                >
                  Loan Rates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: tab === Tab.Savings })}
                  onClick={() => setTab(Tab.Savings)}
                >
                  Savings Rates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: tab === Tab.Fees })}
                  onClick={() => setTab(Tab.Fees)}
                >
                  Fees
                </NavLink>
              </NavItem>
            </Nav>
            {_.isNil(tab) && (
              <h4 className="text-center">
                <i className="fa fa-spin fa-sync" /> Loading...
              </h4>
            )}
            <TabContent className="p-2" activeTab={tab}>
              <TabPane className="child-spacing-y-3" tabId={Tab.Loan}>
                <Row>
                  <Col md={12}>
                    <TermAprSection {...loanRates.newVehicles} />
                    <TermAprSection {...loanRates.usedVehicles} />
                    <TermAprSection {...loanRates.newMotorcycles} />
                    <TermAprSection {...loanRates.usedMotorcycles} />
                    <TermAprSection {...loanRates.newRecreationalVehicles} />
                    <TermAprSection {...loanRates.usedRecreationalVehicles} />
                    <TermAprSection {...loanRates.personalLoans} />
                    <TermAprSection {...loanRates.creditCard} />
                    <TermAprSection {...loanRates.shareCertificateSecured} />
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center">
                    <h5>Ready to Apply?</h5>
                    <p>{loanRates.readyToOpenAccount}</p>
                    <Button color="primary" tag={Link} to="loans/apply">
                      {loanRates.buttonText}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center">
                    <aside className="small">
                      <Markdown source={loanRates.footer} />
                    </aside>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={Tab.Savings}>
                <Row>
                  <Col md={12}>
                    <TermApySection {...savingsRates.savings} />
                    <TermApySection {...savingsRates.certificatesSmall} label={<>Certificates<sup>†</sup> ($1,000 - $49,999)</>} />
                    <TermApySection {...savingsRates.certificatesLarge} label={<>Certificates<sup>†</sup> ($50,000 +)</>} />
                    <TermApySection {...savingsRates.ira} />
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center">
                    <h5>Ready to Open an Account?</h5>
                    <p>{savingsRates.readyToOpenAccount}</p>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center">
                    <aside className="small">
                      <Markdown source={savingsRates.footer} />
                    </aside>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={Tab.Fees}>
                <Row>
                  <Col md={12}>
                    <h4>{fees.effective}</h4>
                    <FeeTable fees={fees.fees} />
                  </Col>
                </Row>

                <Row>
                  <Col className="text-center">
                    <aside className="small">
                      <Markdown source={fees.footer} />
                    </aside>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
};

export default () => <RatesIndexPageInternal {...ratesIndexData} />;
