import React, { ReactNode } from 'react';
import TermApyTable from './TermApyTable';

interface Props {
  label: ReactNode;
  rates: { term: string; apy: string; }[];
}

const TermApySection = ({ label, rates }: Props) => {
  return (
    <div>
      <h4>{label}</h4>

      <TermApyTable rates={rates} />
    </div>
  );
};

export default TermApySection;
