import React, { ReactNode } from 'react';
import TermAprTable from './TermAprTable';

interface Props {
  label: ReactNode;
  rates: { term: string; apr: string; }[];
}

const TermAprSection = ({ label, rates }: Props) => {
  return (
    <div>
      <h4>{label}</h4>

      <TermAprTable rates={rates} />
    </div>
  );
};

export default TermAprSection;
