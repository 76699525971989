import React from 'react';
import _ from 'lodash';
import RateTable from './RateTable';
import { rateTableWidth } from '../../constants';

interface Props {
  fees: { label: string; fee: string; }[];
}

const FeeTable = ({ fees }: Props) => {
  return (
    <RateTable>
      <tbody>
        {
          _.map(fees, (fee, i) => (
            <tr key={i}>
              <td>{fee.label}</td>
              <td style={{ width: rateTableWidth }}>{fee.fee}</td>
            </tr>
          ))
        }
      </tbody>
    </RateTable>
  );
};

export default FeeTable;
